.filters-dialog {
    .MuiDialog-paper {
        width: 35vw;
        height: 90vh;
        top: 25px;
    }
    .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        font-weight: 800;
        font-size: 24px;
    }
    .MuiDialogContent-root {
        padding: 16px 8px 16px 16px;
        border-bottom: none;
    }
    .MuiDialogActions-root {
        justify-content: center;
        flex-direction: column;
        padding: 16px 24px;
    }
}

.inputs-title {
    font-weight: 800 !important;
    font-size: 16px !important;
    padding-bottom: 16px;
    color: #272727;
}

.filters-chips {
    border: 1px solid #E6EAEE !important;
    border-radius: 8px !important;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
}

.selected-chips {
    background-color: #4B80F0 !important;
    color: white !important;
}

.filters-input {
    width: 95%;
    border: 1px solid #E6EAEE !important;
    border-radius: 4px !important;
}

.filters-time-input {
    width: 95%;
    .MuiInputBase-root {
        border-radius: 4px !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #E6EAEE !important;
    }
    .css-hdw1oc {
        width: 0 !important;
    }
}

.filters-input::after, .filters-input::before {
    border: none !important;
}

.delete-field-btn {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    margin-right: 0;
}

.train-number-selector {
    font-family: Avenir;
    font-weight: 400;
    color: #00000099;
    font-size: 15px;
    width: 100%;
    padding-top: 5px;
    padding-left: 10px;
  }