.missing-gov-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    position: fixed;
    width: 480px;
    height: 168px;
    right: 8px;
    bottom: 1%;
    background: #FFFFFF;
    border: 1px solid rgba(252, 81, 48, 0.2);
    box-shadow: 2px 2px 6px rgb(0 0 0 / 8%), -2px -2px 7px rgb(0 0 0 / 6%) !important;
    z-index: 1;
}

.notice {
    padding: 16px;
    background: rgba(74, 129, 240, 0.07);
    border-radius: 8px;
    margin: 36px 0 !important;
}

.missing-gov-icon {
    position: fixed;
    right: 8px;
    bottom: 1%;
    background: #FFFFFF;
    box-shadow: none !important;
}

.type {
    padding-top: 10px;
}

.type-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 10px;
    height: 70vh;

    .missing-day-headers {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .title {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;

            span {
                font-weight: 900;
                font-size: 22px;
            }
        }

        p {
            margin: 0 !important;
        }
    }

    .overflow-container {
        height: 100%;
        overflow: auto;
        .day-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            overflow: auto;

            .day {
                font-weight: 500;
                font-size: 16px;
                color: #FC5C3D;
            }
        }
    }

    .overflow-container::-webkit-scrollbar {
        width: 5px;
      }
      
    .overflow-container::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    
    .overflow-container::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
    
}

.import-button {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;

    button {
        padding: 6px 25px;
        border: none;
        border-radius: 20px;
        background-color: #4A81F0;
        color: #FFFFFF;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06);

        &:hover {
            color: #4A81F0;
            background-color: white;
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            background: #F2F6FE;
            color: #272727;
        }
    }
}