.modal-wrapper-history {
  z-index: 10001;
  &.MuiModal-root {
    background-color: #000000a9;
    z-index: 10001;
  }
}

.modal-history {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
  min-width: 300px;
  max-width: calc(100vw - 300px);
  z-index: 11;

  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .buttons {
      display: flex;
      gap: 20px;
    }

    .infos {
      padding-top: 5px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .info {
        font-weight: 500;
        font-size: 16px;
        display: flex;
        gap: 10px;
        span {
          color: #00072B;
        }
        .label {
          color: #4B80F0
        }

      }
    }

    .title {
      display: flex;
      flex-direction: column;

      font-size: 20px;
      color: #4B80F0;

      .train-number {
        font-weight: 900;
      }
    }

    .close-button {
      display: flex;
      align-items: center;
      padding: 10px;
      border: none;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      font-size: 20px;
      color: #4B80F0;

      &:hover {
        background-color: #f4f5f7;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 30px);
    min-height: calc(100% - 30px);
    overflow: hidden;

    .cell {
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;

      .value {
        font-weight: 800;
      }

      .ratio {
        color: #00072B66;
        font-style: italic;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 10%;
    min-height: 10%;
    margin-bottom: 30px;

    .back-button {
      display: flex;
      align-items: center;
      gap: 10px;
  
      border: 1px solid #E6EAEE;
      border-radius: 8px;
      padding: 5px 15px;
      cursor: pointer;
      transition: all 0.3s;
      background-color: transparent;
  
      &:hover {
        background-color: #4B80F0;
        color: white;
        border-color: #4B80F0;
      }
  
    }

    .export {
      display: flex;
      justify-content: end;
      margin-right: 50px;
    }
  
  }
}

.modal-detail::-webkit-scrollbar {
  width: 5px;
}

.modal-detail::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.modal-detail::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}