.modal-wrapper {
  z-index: 1000;
  &.MuiModal-root {
    background-color: #000000a9;
    z-index: 1000;
  }
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 24px;
  border-radius: 8px;
  max-height: 95vh;
  overflow-y: auto;
  width: calc(100vw - 48px);
  min-width: 300px;
  max-width: 550px;
  z-index: 11;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 30px);
    min-height: calc(100% - 30px);
    overflow: hidden;
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 10%;
    min-height: 10%;
  
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-carbone);
      line-height: 140%;
      margin: 0;
    }
  
    button {
      border: none;
      background-color: white;
      cursor: pointer;
      font-size: 20px;
      height: 30px;
      color: var(--color-carbone);
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding: 3px;
      &:hover, &:focus {
        background-color: #F5F6FA;
        border-radius: 50%;
      }

      &:focus {
        border: 2px solid var(--color-carbone);
        padding: 1px;
      }
    }
  }

  .primary {
    h2 {
      color: var(--color-medium-blue-100);
    }
    button {
      color: var(--color-medium-blue-100);
      &:focus {
        border: 2px solid var(--color-medium-blue-100);
      }
    }
  }

  .secondary {
    h2 {
      color: var(--color-purple);
    }
    button {
      color: var(--color-purple);
      &:focus {
        border: 2px solid var(--color-purple);
      }
    }
  }
}
