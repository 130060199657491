.custom-tooltip {
  background: black;
  padding: 9px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  opacity: 0.90;
  color: #FFF;
  text-align: center;

  .conflict-container {
    margin-top: 10px;
  }

  .tooltip-line {
    display: flex;
    gap: 5px;
  }

  .bold {
    font-weight: 900;
  }

  .label {
    font-size: 1rem;
    font-weight: 900;
  }

  .number {
    font-size: 1rem;
    font-weight: 400;
  }
}

.missing-data {
  position: absolute;
  top: 65%;
  left: 45%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 0.5s ease 0.5s forwards;
}

.no-data-line {
  position: relative;
  top: -50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}