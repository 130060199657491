.reusability {
  margin-top: 20px;

  .actions {
    display: flex;
    justify-content: end;
    margin-right: 50px;
  }

  // Table Component
  .reusability-table {
    background-color: #F8F9FC;
    min-height: 600px;
    padding: 40px 20px 40px 20px;

    .reusability-table-title {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;
      .title {
        font-size: 20px;
        display: flex;
        justify-content: center;
        gap: 5px;

        .bold {
          font-weight: 800;
        }        
      }

      .export {
        display: flex;
        justify-content: end;
        margin-right: 50px;
      }
      margin-bottom: 20px;
    }

    .cell-libelle {
      font-weight: 500;
    }
    .cell {
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;

      &.disabled {
        cursor: default;
      }

      .value {
        font-weight: 800;
      }

      .ratio {
        color: #00072B66;
        font-style: italic;
      }
    }
  }

  .missing-data {
    position: relative;
    max-width: 600px;
    top: -70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    opacity: 0;
    animation: fadeIn 0.5s ease 0.5s forwards;
  }

  .no-data-table {
    position: absolute;
    top: 140%;
  }
}

.table-head-cell {
  min-width: 120px;
  max-width: 120px;
}

.table-head-cell:first-child {
  min-width: 60px;
  max-width: 60px;
}