body {
	background-color: '#F6F6F6' !important;

}
.card-action:focus {
	outline: none;
}

.mastcontainer {
	padding-top: 3.75rem;
  }
  
  @media (min-width: 1280px) {
	.mastcontainer {
	  padding-top: 4.0625rem;
	}
  }
	svg {
		overflow: visible !important;
	}
#atlwdg-trigger {
	left: 0;
	right: auto;
}
  