.body-alter-background {
	background-color: white !important;
  min-height: 100vh;
}

.modified {
  background-color: #ffa149;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
}

/* .no-value {
	background-color: #F6F6F6;
} */

.table-header {
  background: linear-gradient(180deg, #E8F0F9 0%, #E8F0F9 100%);
  padding: 12px !important;
  font-weight: bold !important;
}

.table-header:first-child {
  border-right: 1px solid rgba(224, 224, 224, 1)
}

.table-cell-border:nth-child(7) {
  border-right: 1px solid rgba(224, 224, 224, 1)
}

.table-subheader {
  top: 48px !important;
  padding: 12px !important;
  font-weight: bold !important;
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
} 

.detail-variation-select legend span {
  display: none !important;
}

.MuiInputBase-root {
  border-radius: 8px !important;
  margin-right: 0 !important
}

.only-one:nth-child(n+9) {
  display: none;
}

.only-one:nth-child(1) {
  display: none;
}

.only-one:nth-child(2) {
  display: none;
}
.only-one:nth-child(3) {
  display: none;
}
.only-one:nth-child(4) {
  display: none;
}
.only-one:nth-child(5) {
  display: none;
}
.only-one:nth-child(6) {
  display: none;
}

.only-one {
  background-color: #F7F7F7;
  color: #4B80F0 !important;
}

.only-one-cell {
  background-color: #F7F7F7;
  color: #F7F7F7 !important;
}
.main-title {
  font-size: 28px;
}

.variation {
  margin: 0;
}

.sub-title{
  color: #7B8692;
  font-size: 16px;
  font-weight: lighter;
}

.info {
  font-size: 14px;
  margin-bottom: 15px;
}

.paper-test::before, .paper-test::after {
  content: '';  /* Insert pseudo-element */
  margin: auto; /* Make it push flex items to the center */
}

.bg-missing-data {
  background: rgba(230, 234, 238, 0.7)
}

.badge-style {
  .MuiBadge-badge {
    color: white;
    background-color: #FC5130;
    right: 10px;
    top: 5px;
  }
}

@keyframes barstrokeout {
  from {stroke-width: 0px;}
  to {stroke-width: 15px;}
}

@keyframes barstrokein {
  from {stroke-width: 15px;}
  to {stroke-width: 0px;}
}

.focus-visible {
  background-color: #FFFFFF !important;
}

.pagination-lines-per-page {
  display: flex;
  justify-content: flex-end;
  .MuiToolbar-root {
    display: flex;
    min-height: auto;
  }
  .MuiTablePagination-selectLabel {
    order: 1;
    margin-left: auto;
    justify-content: center;
    margin-bottom: 0;
  }
  .MuiInputBase-root {
    display: flex;
    order: 4;
  }
  .MuiTablePagination-select {
    border: 1px solid #E6EAEE;
    border-radius: 8px;
    display: flex;
    align-items: center;
    min-height: 25px !important;
    margin-right: 0;
  }
  .MuiTablePagination-actions {
    display: none !important;
  }
  .MuiTablePagination-displayedRows {
    display: none !important;
  }
}

.center-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.secondary-hover {
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  color: #99B7F6;
}