.tabs-menu {
  &.Mui-selected {
    color: white !important;
    background-color: #4b80f0 !important;
  }
  &.MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
}

.MuiTabs-flexContainer {
  flex-wrap: wrap;
  justify-content: center;
}
