@import "variables.scss";
@import "mixins.scss";

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  // border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__header {
  text-align: center;
  background-color: $datepicker__background-color;
  // border-bottom: 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  padding: 5px 0 0px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  font-weight: bold;
  font-size: $datepicker__font-size * 1.18;
  margin-bottom: 10px;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: $datepicker__navigation-button-size;
  width: $datepicker__navigation-button-size;
  text-indent: -999em;
  overflow: hidden;

  &--previous {
    left: 2px;
  }

  &--next {
    right: 2px;

  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
    }

    &-upcoming {
      top: -4px;
    }
  }

  &:hover {
    *::before {
      // border-color: darken($datepicker__muted-color, 15%);
    }
  }
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;

  &::before {
    @extend %navigation-chevron;
  }

  &--next {
    left: -2px;

    &::before {
      transform: rotate(45deg);
      left: -7px;
    }
  }

  &--previous {
    right: -2px;

    &::before {
      transform: rotate(225deg);
      right: -7px;
    }
  }
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: $datepicker__margin;
  text-align: center;

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  
}

.react-datepicker__day-names {
  color: #61B8FA !important;
  font-weight: 900;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__month,
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__background-color;
  }

  // &--today {
  //   font-weight: bold;
  // }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    // background-color: #fff;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $datepicker__selected-color;
    }
  }

  &:hover {
    background-color: $datepicker__background-color;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;
  position: relative;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %navigation-chevron;

    transform: rotate(135deg);
    right: -16px;
    top: 0;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}


.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  // font-weight: bold;
  padding: 5px 0;
  clear: left;
}
