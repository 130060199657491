.modal-wrapper {
  z-index: 10000;
  &.MuiModal-root {
    background-color: #000000a9;
    z-index: 10000;
  }
}

.modal-detail {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  max-height: 85vh;
  overflow-y: auto;
  width: 100%;
  min-width: 300px;
  max-width: calc(100vw - 100px);
  z-index: 11;

  .first-column {
    max-width: 10px;
  }

  .cell {
    padding: 16px 0px;
    width: 30px;
  }

  .cell-head {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 30px);
    min-height: calc(100% - 30px);
    overflow: hidden;

    .cell-history {
      padding: 16px 0px;
      button {
        border: none;
        background-color: transparent;
        color: #4B80F0;
        cursor: pointer;
        text-decoration: underline;
        min-width: 110px;
      }
      
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 10%;
    min-height: 10%;
    margin-bottom: 30px;

    .back-button {
      display: flex;
      align-items: center;
      gap: 10px;
  
      border: 1px solid #E6EAEE;
      border-radius: 8px;
      padding: 5px 15px;
      cursor: pointer;
      transition: all 0.3s;
      background-color: transparent;
  
      &:hover {
        background-color: #4B80F0;
        color: white;
        border-color: #4B80F0;
      }
  
    }

    .export {
      display: flex;
      justify-content: end;
      margin-right: 50px;
    }
  
  }
}

.modal-detail::-webkit-scrollbar {
  width: 5px;
}

.modal-detail::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.modal-detail::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}