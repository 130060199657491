.conflict {
  min-height: 70vh;
  padding-top: 20px;
}

.line {
  margin-top: 140px;
  margin-bottom: 60px;

  .title {
    margin-bottom: 20px;
  }
}

.conflict-graph {

  .select-container {
    margin-top: 20px;
    margin-right: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .select {
      width: 250px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 50vh;
}

.bar-header {
  margin-top: -60px;
  margin-bottom: -20px;
}

@media screen and (max-width: 1000px){
  .conflict {
    .conflict-bar {
      .title {
        margin-left: 0px;

        h1 {
          font-size: 20px;
        }
      }
    }
  }
}

@media (min-width: 1001px) and (max-width: 1470px){
  .conflict {
    .conflict-bar {
      .title {
        margin-left: 100px;
        h1 {
          font-size: 24px;
        }
      }
    }
  }
  
}