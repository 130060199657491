$datepicker__background-color: #fff !default;
$datepicker__border-color: #fff !default;
$datepicker__highlighted-color: #216ba5 !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
// $datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 50% !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Avenir", helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 1rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 30px !default;
