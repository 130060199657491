.drop-zone {
  display: flex;
  background: #022e4f;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-style: dotted;
  border-color: rgba(255, 255, 255, 0.7);
}

.drop-zone-light {
  font-weight: 500;
  display: flex;
  background: #FCFCFC;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(68, 84, 127);
  border: 2px;
  border-style: dashed;
  border-color: rgba(68, 84, 127, 0.5);
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.tracks-list {
  background: white;
  width: 100%;
  border: solid 1px black;
}
