// Header
.graph-title {
  text-align: center;
  h1 {
    font-size: 32px;
    font-weight: 800;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #616465;
  }
}

// Tooltip
.custom-tooltip {
  background: black;
  padding: 9px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  opacity: 0.90;
  color: #FFF;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .total {
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .selected {
    font-size: 1.1rem;
    font-weight: 900;
  }

  .item {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .conflict-container {
    margin-top: 10px;
  }

  .tooltip-line {
    display: flex;
    gap: 5px;
  }

  .bold {
    font-weight: 900;
  }

  .label {
    font-size: 1rem;
    font-weight: 900;
  }

  .number {
    font-size: 1rem;
    font-weight: 400;
  }
}

.info-header {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
  margin-top: 20px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    gap: 10px;
    background-color: #F8F9FC;
    padding: 28px 32px;
    border-radius: 15px;

    .libelle {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #3673F4;
    }

    .number {
      font-size: 42px;
      font-weight: 900;
      text-align: center;
      color: #3673F4;
    }

    
  }

  .null {
    .libelle {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #7e889e;
    }

    .number {
      font-size: 22px;
      min-height: 55px;
      font-weight: 900;
      text-align: center;
      color: #7e889e;
    }
  }
}