:root {
  // colors
  --color-carbone: #242830;
  --color-carbone-300: #797979;
  --color-carbone-500: #545454;

  --color-white: #ffffff;
  --color-near-white: #f5f6f6;
  --color-lighter-grey: #eeeeee;
  --color-light-grey: #c6c6c6;
  --color-medium-grey: #838383;
  --color-near-black: #272727;
  --color-grey-900: #3c3c3b;
  --color-grey-800: #575f6f;
  --color-grey-500: #919191;

  --color-grey-300: #c8c8c8;
  --color-grey-200: #e3e3e3;
  --color-grey-100: #e2e7ef;

  --color-lighter-grey-blue: #F4F6FB;

  --color-blue-500: #41a8f9;
  --color-blue-600: #1294f8;
  --color-blue-700: #0673c6;
  --color-blue-900: #022e4f;
  --color-light-blue: #f1f8ff;
  --color-medium-blue: #546efe;
  --color-medium-blue-100: #4B80F0;
  --color-medium-blue-500: #445EF6;

  --color-yellow-500: #fecc00;
  --color-light-yellow: #fdf2d0;

  --color-red-400: #fd7b62;
  --color-red-500: #fc5130;
  --color-red-600: #e83f3f;

  --color-green-500: #04e762;
  --color-green-900: #09c959;

  --color-warning-900: #ee9207;

  --color-purple-500: #832667;
  --color-purple: #964BF5;

  --color-personal-token: #f7eaf9;
  --color-external-token: #def7de;
  --color-service-token: #f6f5c5;

  --color-valid-token: #31c752;
  --color-pending-token: #ee9207;
  --color-rejected-token: #eb604d;

  --color-theme-select: #2877f9;
  --color-topbar: #01080e;

  --color-circulation-on-time: #009f00;
  --color-circulation-late: #dad300;
  --color-circulation-very-late: #c80006;

  // sizes
  --topbar-height: 65px;
  --userbar-height: 68px;
  --tabsbar-height: 48px;
  --banner-height: 50px;
}
